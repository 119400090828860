import request from '@/utils/request'

// 添加菜单
export const createMenu:any = (data: any) => {
    return request({
      url: '/resource',
      method: 'post',
      data
    })
  }

// 查询菜单列表
export const searchMenu:any = (params: any) => {
    return request({
      url: '/resource/list',
      method: 'get',
      params
    })
  }

// 删除菜单
export const deleteMenu:any = (id: any) => {
    return request({
      url: `/resource/${id}`,
      method: 'delete',
    })
  }

export const updataMenu:any = (data:any) => {
    return request({
        url:`/resource`,
        method:'put',
        data
    })
}

export const getMenuInfo:any = (id:any) => {
  return request({
    url: `/resource/${id}`,
    method:'get',
  })
}


